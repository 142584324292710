html, body {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    padding: 0;
  }

h1 {
    margin: 2em 0 ;
    color: #333;
    text-shadow: 0 10px 20px #5b5b5b91
}
h2 {
    font-weight: normal;
}
h3 {
    font-weight: normal;
}
.fwb {font-weight: bold}
.red {color:#eb1b23}
.decimal{font-size: 0.7em; letter-spacing: -1.4px}
.App {
    margin: 0;
    padding: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
}

.bold {
    font-weight: bold;
}

.roboto-slab {
    font-optical-sizing: auto;
  }
  

*{
    scroll-behavior: smooth;
    box-sizing: border-box;
}

header {
    display: flex;
    align-items: start;
    background-color: #eb1b23;
    width: 100%;
    box-sizing: border-box;
    color: #f9f1f0;
    border-bottom: 1px solid #EB1B23;
    position: relative;
}

.phone-number {
    margin-left: 6em;
}

.phone-number-container {
    display: flex;
}

.main-logo {
    background-color: inherit;
    z-index: 1000;
}

.address-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1.50px solid #ddd;
    background-color: #fcfcfc;
    border-radius: 6px;
    margin: 10px;
    padding: 20px;
    height: 4.5em;
    max-height: 6em;
    max-width: 95%;
}

.address-section:hover{
    cursor: pointer;
}

.restorant-foto {
    width: 80em;
    border-radius: 6px;
}

#Menu-title {
    margin-top: 2em;
}

.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.menu {
    display: flex;
    flex-wrap: wrap;
    gap: 70px;
    box-sizing: border-box;
}

.menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 5px;
    width: 400px;
    height: 800px;
    max-height: 450px;
    box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
    background: radial-gradient(#0000001f, transparent);
}

.menu-item-title {
    font-size: 1.5em;
    text-align: center;
}


.menu-item-description {
    font-size: 1em;
    color: #5b5b5b;
    flex-grow: 1; 
    margin: 0 auto 1em auto;
    text-align: center;
}

.menu-item-price {
    font-size: 1.2em;
    color: #000;
}

.menu-item-image {
    object-fit: cover; 
    border-radius: 50%;
    height: 200px;
    max-width: 200px;
    border: 1px solid black;
}

.cart-price-and-button{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.google-comments {
    overflow: hidden;
}

.add-to-cart-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #1e1f22;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    margin: 10px 0;
}

.add-to-cart-button i {
    margin-right: 8px; 
}


.out-of-range-error-container{
    display: flex;
    color: red;
    flex-direction: column;
    width: 80%;
    text-align: start;
    gap: 0;
    padding: 0 1.8em;
    font-size: 0.9em;
}

.warning-icon {
    margin-right: 5px;
}

.add-to-cart-button:hover {
    text-decoration: none;
    background-color: #ec1c24;
    transition: 0.2s;
}

.cart-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f6e197;
    border: 3px solid #efc83c;
    border-radius: 6px;
    position: fixed;
    bottom: 20px;
    right: 60px;
    max-width: 30em;
}

.product-section-inside-cart {
    max-height: 55vh;
    overflow-y: auto;
}

.product-section-inside-cart::-webkit-scrollbar {
    width: 10px;
}

.product-section-inside-cart::-webkit-scrollbar-track {
    background-color: #f6e197;
}

.product-section-inside-cart::-webkit-scrollbar-thumb {
    background-color: #efc83c;
    border-radius: 6px;
}

.product-section-inside-cart::-webkit-scrollbar-thumb:hover {
    background-color: #aa8707;
}


.cart-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;
    margin-top: 5px;
}

.inside-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30em;
}

.close-cart {
    position: absolute;
    padding: 5px 10px;
    top: 5px;
    right: 10px;
    border: none;
    border-radius: 50%;
    font-size: 16px;
    cursor: pointer;
    background-color: inherit;
    color: black;
    font-size: larger;
}

.cart-price-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.close-cart:hover {
    color: white;
    background-color: #ed3954;
    transition: 0.3s;
}

.product-inside-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1.50px solid #ddd;
    background-color: #fcfcfc;
    border-radius: 6px;
    margin: 10px;
    width: 26.5em;
    max-height: 7em;
}

.product-top {
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    align-items: center;
}

.location-off-error-message {
    display: flex;
    color: red;
    font-weight: bold;
    font-size: larger;
    margin-bottom: 30px;
}

.google-location-icon {
    margin-right: 5px;
}

.order-button:disabled {
    font-weight: bold;
    font-size: large;
    opacity: 1; 
    cursor: default; 
    background-color: inherit; 
    color: red; 
    border: none; 
    margin: 0;
}

.order-button:disabled:hover {
    background-color: inherit;
}

.product-inside-cart-image {
    margin-left: 15px;
    object-fit: cover; 
    box-sizing: border-box;
    margin-top: 1.5em;
    width: 70px;
    height: 70px;
    border-radius: 6px;
}

.product-buttons {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    width: 100%;
    gap: 200px;
}

.remove-from-cart-button {
    display: flex;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    width: 25px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 6px;
    color: red;
}

.quantity-numbers {
    font-weight: 400;
    margin-top: 10px;
}

.product-inside-cart-title{
    overflow: hidden;
    max-height: 50px;
    max-width: 200px;
    margin-right: auto;
    margin-left: 30px;
}

.product-inside-cart-price {
    font-weight: 500;
    margin-right: 25px;
}

.quantity-button {
    margin-top: 10px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    color: red;
    font-size: large;
    height: 20px;
    padding: 0;
    font-weight: 550;
    cursor: pointer;
}

.quantity-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-left: 20px;
    margin-bottom: 6px;
}


.order-button-text {
    display: flex;
    align-items: center;
}

.fa-whatsapp {
    margin-right: 5px;
}

.order-deliver-line{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    gap: 5px;
}

.deliver-span{
    font-size: large;
    font-weight: bold;
    color: #1fcf37;
    margin: 0;
    padding: 0;
}

.deliver-icon{
    width: 24px;
}

.empty-cart {
    flex-direction: column;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.cart-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-bottom > h3 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.product-quantity-in-cart{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    bottom: 2.3em;
    left: 2.2em;
    position: absolute;
    background-color: red;
    padding: 10px 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.update-my-address {
    background-color: #4285F4; 
    color: white;
    border: none;
    border-radius: 14px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }

  .fa-triangle-exclamation {
    margin-right: 5px;
  }
  
  .update-my-address:hover {
    background-color: #357AE8; 
  }
  
  .update-my-address:active {
    background-color: #2A6DBA;
  }
  

.product-quantity-in-cart > p {
    font-weight: bold;
    font-size: smaller;
}

.map-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.map-section > h1 {
    margin-top: 2em;
    margin-bottom: 1em;
}

 #gmap {
    border: none;
    height: 45em;
    width: 100vw;
    box-sizing: border-box;
}

#location-title {
    margin-top: 5em;
    margin-bottom: 1em;
}

.footer-wrapper {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    background-color: #222831;
    color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.footer-wrapper p {
    margin-bottom: 20px;
    font-size: 1.1em;
}

.footer-wrapper p:last-child {
    margin-bottom: 0;
}

.review-button {
    display: flex;
    align-items: center;
    margin: 4em;
    padding: 10px 20px;
    font-size: 20px;
    background-color: #6ea6ff; 
    width: auto;
    height: 4em;
    color: white; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s; 
}

.google-icon {
    width: 50px;
    height: auto;
    margin-right: 15px;
}
#cartTotal {
font-weight: bold;
    font-size: 1.3em;
}
.review-button:hover {
    background-color: #39db64;
}

@media (max-width: 768px) {

    .out-of-range-error-container{
        width: 100vw;
    }

    .cart-section {
        background-color: #f6e197;
        border: 3px solid #efc83c;
        border-radius: 6px;
        bottom: 20px;
        right: 10px;
    }

    .product-section-inside-cart::-webkit-scrollbar {
        width: 6px;
    }

    .product-section-inside-cart{
        max-height: 50vh;
        margin-right: auto;
        margin-left: auto;
        overflow-x: hidden;
    }

    .product-inside-cart{
        max-width: 95vw;
    }
    
    .address-modal > h2{
        font-size: larger;
    }

    .product-top {
        margin: 0;
    }

    .cart-price-wrapper{
        font-size: small;
    }

    .first-order-button {
        height: 3em;
    }
    
    .cart-section.open {
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column; 
        justify-content: space-between; 
    }


    .cart-section.open.empty{
        bottom: 20px;
    }

    .inside-cart{
        margin-top: auto;
        margin-bottom: auto;
    }

    .inside-cart.empty-cart{
        height: 10px;
    }

    .review-button {
        margin-top: 0.25em;
        padding: 10px 20px;
        font-size: 20px;
        background-color: #4285f4; 
        width: auto;
        height: 4em;
        color: white; 
        border: none; 
        border-radius: 5px; 
        cursor: pointer; 
        transition: background-color 0.3s; 
    }
    
    .menu {
        display: flex;
        gap: 20px;
        box-sizing: border-box;
        flex-direction: column;
    }

    
    .menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #ddd;
        padding: 30px;
        border-radius: 5px;
        margin: 10px;
        width: 80vw;
    }

    #gmap{
        width: 90vw;
        height: 60vh;
    }

    .footer-wrapper {
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
    }

    .footer-wrapper div {
        margin: 0 20px;
    }

}

#google-reviews {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    gap: 1.5em;
    flex-wrap: wrap; 
    font-size: large;
    margin-top: 2em;
}

.review-item-long {
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(190, 190, 190, .35);
    box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
    padding: 1em;
    max-width: 30em; 
    min-width: 20em; 
    height: 15em; 
    box-sizing: border-box; 
    background-color: white;
    border-radius: 6px;
}

.review-item{
    display: flex;
    flex-direction: column;
    border: solid 1px rgba(190, 190, 190, .35);
    box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
    padding: 1em;
    max-width: 30em; 
    min-width: 20em; 
    height: 15em; 
    box-sizing: border-box; 
    background-color: white;
    border-radius: 6px;
}

.review-meta, .review-stars {
    text-align: center;
    font-size: 115%;
    margin-bottom: 5px;
}

.review-author {
    text-transform: capitalize;
    font-weight: bold;
}

.review-date {
    opacity: .6;
    display: block;
}

.review-text {
    font-size: smaller;
    line-height: 1.55;
    text-align: left;
    margin: auto;
    color:#5b5b5b;
}

.review-stars ul {
    display: inline-block;
    list-style: none !important;
    margin: 0;
    padding: 0;
}

.review-stars ul li {
    float: left;
    list-style: none !important;
    margin-right: 1px;
    line-height: 1;
}

.review-stars ul li i {
    color: #eb6e00;
    font-size: 1.4em;
    font-style: normal;
}

.review-stars ul li i.inactive {
    color: #c6c6c6;
}

.star:after {
    content: "\2605";
}

@media (max-width: 450px) {
    #google-reviews {
        flex-direction: column;
        align-items: center;
    }

    .review-item-long {
        max-width: 90%;
        min-width: auto;
        height: auto;
    }

    .review-item{
        max-width: 90%;
        min-width: auto;
        height: auto;
    }
}

.checking-location-button {
    color: red;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 1.5em;
}

.checking-location-button:hover {
    cursor: pointer;
    text-decoration: underline;
}
  
  .get-location {
    background-color: #3b82f6; 
    color: white;
  }

  .show-map {
    background-color: #ef4444; 
    color: white;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  
  .map-container {
    position: relative; 
    width: 900px; 
    height: 600px; 
    padding: 5px;
    padding-bottom: 85px;
    background: white;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    color: red;
    background: none;
    border: none;
    font-size: large;
    font-weight: 00;
    cursor: pointer;
    z-index: 5; 
  }


.loading-bar {
  position: absolute;
  top: 30%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%); 
  width: 40px; 
  height: 40px; 
}

.loading {
  border: 4px solid #ddd; 
  border-top: 4px solid #4caf50; 
  border-radius: 50%;
  animation: spin 1s linear infinite; 
  width: 100%;
  height: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.menu-item-image.hidden {
  display: none; 
}

.address-modal {
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 50%;
    animation: fadeIn 0.3s ease;
    height: 37.5em;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

input:focus {
    border-color: #007bff;
    outline: none;
}

.order-button {
    background-color: #25D366;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.order-button-text {
    display: flex;
    align-items: center;
}


.cancel-button {
    background-color: #f44336; 
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.cancel-button:hover {
    background-color: #d32f2f;
}

.order-button:hover {
    background-color: #20b958;
}

.warning-text {
    color: #d9534f; 
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

.first-order-button {
    background-color: #28a745; 
    color: white; 
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    display: inline-flex;
    align-items: center;
    width: 10em;
}

.first-order-button:hover {
    background-color: #218838; 
}

.first-order-button:disabled {
    background-color: #a3d5a0; 
    color: white; 
    cursor: not-allowed;
}

.first-order-button > p {
    height: 20px;
    padding: 0;
    margin: 0 0 0 3px;
}

.address-modal > h2 {
    font-weight: 500;
    margin: 0 auto 1em auto;
}


.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-menu {
    list-style: none; 
    display: flex; 
    justify-content: space-around; 
    margin: 0; 
    padding: 0; 
}

.nav-item {
    display: flex; 
    align-items: center; 
}

.nav-link {
    color: #fff; 
    text-decoration: none; 
    font-size: 1.5em; 
    font-weight: bold; 
    transition: color 0.3s ease; 
    padding: 10px 15px; 
}

.nav-link:hover {
    color: #efc83c; 
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 5px; 
}

.navbar {
    background-color: #ec1c24; 
    border-bottom: 1px solid #ec1c24; 
    padding: 10px 20px; 
    width: 100%; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    position: fixed; 
    top: 0; 
    left: 0; 
}

.nav-link:hover {
    color: #efc83c;
    background-color: rgba(255, 255, 255, 0.2); 
}


.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.line {
    width: 25px;
    height: 3px;
    background: #fff;
    margin: 2px;
    transition: all 0.3s;
}

.line.open:nth-child(1) {
    transform: rotate(50deg) translate(5px, 5px);
}

.line.open:nth-child(2) {
    opacity: 0;
}

.line.open:nth-child(3) {
    transform: rotate(-55deg) translate(5px, -5px);
}


.nav-menu {
    max-height: 0; 
    overflow: hidden; 
    transition: max-height 0.3s ease-out; 
    position: absolute;
    top: 60px; 
    left: 0;
    right: 0;
    background: #ec1c24; 
    flex-direction: column;
    border-top: 1px solid #ddd;
}

.nav-menu.inactive {
    display: none;
}

.nav-menu.active {
    max-height: 300px; 
    display: flex; 
    margin-top: 4em;
    border: none;
}



@media (max-width: 768px) {

    .pc-navbar{
        display: none;
    }

    .hamburger {
        display: flex; 
    }

    .nav-menu {
        flex-direction: column;
        align-items: center;
        padding: 10px 0; 
    }

    .nav-item {
        width: 100%; 
        text-align: center; 
    }

    .nav-link {
        padding: 15px; 
        font-size: 1.2em; 
    }

    .navbar-pc{
        display: none;
    }
}

@media (min-width: 768px) {

    #small-logo{
        display: none;
    }

    .navbar-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100px;
    }

    .nav-menu.active{
        position: absolute;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        bottom: 1;
    }

    .navbar-container{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .navbar-pc-container {
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: space-around ;
    }
}

.warning-message{
    margin-bottom: 0.5em;
    padding: 0;
}

.delivery-fee {
    color: red;
    margin-left: 5px;
    border-bottom: 2px solid red;
}

.combined-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 80em;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

input[type="text"]:focus {
    border-color: #007bff;
    outline: none;
}

.order-button, .cancel-button, .update-my-address {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.order-button {
    background-color: #25D366; 
    color: white;
}

.order-button:hover {
    background-color: #128C7E;
}

.cancel-button {
    background-color: #f44336; 
    color: white;
}

.cancel-button:hover {
    background-color: #d32f2f;
}

.warning-text {
    color: red;
    margin-bottom: 10px;
}

.update-my-address {
    background-color: #007bff; 
    color: white;
}

.update-my-address:hover {
    background-color: #0056b3;
}

.order-button-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.order-button, .cancel-button {
    min-width: 200px;
}

.map-container input {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
}


.warning-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
  }
  
  .warning-modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .warning-modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #d9534f; 
  }
  
  .warning-modal-message {
    font-size: 16px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .warning-modal-button {
    background-color: #d9534f; 
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .approve-modal-button{
    background-color: #28a745; 
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .approve-modal-button:hover{
    background-color: #218838;
  }
  
  .warning-modal-button:hover {
    background-color: #c9302c; 
  }

  .warning-modal-button-wrapper{
    display: flex;
    justify-content: center;
    gap: 0.5em;
  }
  
.fa-location-dot{
    color: red;
    margin: 10px;
    display: block;
    margin-bottom: 20px;
}

.copyright{
    margin-top: 2.5em;
    font-size: smaller;
    font-weight: 100;
}

.iletisim {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 600;
}

.mail{
    margin: 0;
}

.yemeksepeti-icon {
    width: 4.5em;
    border-radius: 16px;
    cursor: pointer;
}

.trendyol-icon {
    width: 7.6em;
    border-radius: 16px;
    margin-right: 2em;
}

.address-bars{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px;
    padding: 10px; 
}

.fa-map-location-dot {
    margin-left: 10px;
}

@media (max-width: 768px) {

    .combined-modal{
        display: flex;
        flex-direction: column;
        padding-top: 2.5em;
        flex-direction: column-reverse;
        height: 85vh;
        max-height: 85vh;
        position: relative;
        align-items: center;
    }

    .modal-overlay{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .address-bars{
        padding: 0;
        margin-bottom: 0.5em;
    }

    .address-modal{
        padding-top: 1em;
        width: 100%;
        height: 45%;
        position: absolute; 
        bottom: 0;
    }

    .map-container{
        width: 100%;
        height: 50%;
        position: absolute;
        top: 0;
        padding-bottom: 0;
    }

    .marker{
        margin-top: 65px;
    }

    .modal-content{
       padding: 5px;
    }

}

.modal-close-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 95%;
}

.no-scroll {
    overflow: hidden; 
    height: 100%; 
}


.delivery-message {
    margin-top: 10px;
    color: #333; 
    padding: 10px 15px; 
    border: 2px solid #fbc02d; 
    border-radius: 5px; 
    font-weight: bold; 
    text-align: center; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.delivery-message.hidden {
    border: none; 
    padding: 0;   
    margin: 0;   
    height: 0;   
    overflow: hidden; 
}

.min-cart-price{
    margin: 0;
}

.creator-name{
    display: flex;
    margin-top: 2em;
    font-weight: 100;
    opacity: 20%;
}

.map-only-modal{
    background-color: white;
    border-radius: 10px;
    height: 85vh;
    width: 80vw;
}

.order-radious-text{
    margin-top: 0.2em;
    text-decoration: underline;
}

.order-radious-text:hover{
    cursor: pointer;
}

.fa-map-pin{
    margin-right: 5px;
}

.kucukyali-pilavsoleni{
    display: flex;
    text-align: center;
    flex-direction: column;
}

.navbar{
    z-index: 1;
}

.location-header-wrapper{
    padding: 0;
}